<div>
    <h1>FAQ</h1>
</div>

<form (ngSubmit)="createFaq()" [formGroup]="faqForm">
    <nb-card>
        <nb-card-header class="align-text-center">
            <h4>FAQ form</h4>
        </nb-card-header>
        <nb-card-body>
            <div class="item">
                <input nbInput class='full-width' status="primary" formControlName="title" required placeholder="Title">
            </div>
            <div class="item">
                <input nbInput class='full-width' status="primary" formControlName="answer" required
                       placeholder="Answer">
            </div>
        </nb-card-body>

        <nb-card-footer>
            <div class="full-width center-button">
                <button class="left-align-button" status="primary" nbButton>Submit</button>
            </div>
        </nb-card-footer>

    </nb-card>
</form>


<div *ngIf="faqsPagination">

    <table mat-table [dataSource]="faqsPagination.items" #table>

        <div *ngIf="faqsPagination">
            <div *ngFor="let column of columns">
                <ng-container matColumnDef="{{column.columnName}}">
                    <th mat-header-cell *matHeaderCellDef>{{column.prettyColumnName}}</th>
                    <td mat-cell *matCellDef="let element"> {{element[column.columnName]}}</td>
                </ng-container>
            </div>
        </div>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button nbButton size="small" status="success" (click)="openDialog('Update',element)">Edit</button>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button nbButton size="small" status="danger" (click)=deleteFaq(element.id)>Delete</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <app-pager
            [totalItems]="faqsPagination?.meta?.totalItems"
            (newItemEvent)="getFaqPagination($event)">
    </app-pager>
</div>

