import {Component, OnInit} from '@angular/core';
import {AdvertisementsService} from "../service/advertisements/advertisements.service";
import {AuthService} from "../service/auth/auth.service";
import {ColumnDto} from "../model/column.dto";
import {AdvertisementDto} from "../model/advertisement.dto";
import {zip} from "rxjs";

@Component({
    selector: 'app-advertisements',
    templateUrl: './advertisements.component.html',
    styleUrls: ['./advertisements.component.css']
})
export class AdvertisementsComponent implements OnInit {

    advertisements: AdvertisementDto[] | undefined
    placements: string[] | undefined
    userRoles: string[] | null = []
    columns: ColumnDto[] | undefined


    constructor(private _advertisementsService: AdvertisementsService,
                private _authService: AuthService) {
    }

    ngOnInit(): void {
        this.init()

        this.userRoles = this._authService.getUserRoles()
    }

    deleteAdvertisementById(id: string) {
        this._advertisementsService.deleteAdvertisement(id)
            .subscribe(() => this.init())
    }

    updateAdvertisement(advertisement: AdvertisementDto) {
        this._advertisementsService.updateAdvertisement(advertisement)
            .subscribe({
                next: () => this.init(),
                error: err => {
                    alert(err.error.message)
                }
            })
    }

    createAdvertisement(advertisement: AdvertisementDto) {
        this._advertisementsService.createAdvertisement(advertisement)
            .subscribe({
                next: () => {
                    this.init()
                    alert('Advertisement has been created')
                },
                error: err => {
                    alert(err.error.message)
                }
            })
    }

    init() {
        zip([
            this._advertisementsService.getAllAdvertisements(),
            this._advertisementsService.getPossiblePlacements(),
            this._advertisementsService.getColumns()
        ]).subscribe(([advertisements, placements, columns]) => {
            this.advertisements = advertisements
            this.placements = placements
            this.columns = columns
        })
    }
}
