import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {JsonEditorOptions} from "ang-jsoneditor";
import {DialogActions} from "../model/dialog-actions.enum";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ColumnDto} from "../model/column.dto";

@Component({
    selector: 'app-dialog-box',
    templateUrl: './dialog-box.component.html',
    styleUrls: ['./dialog-box.component.css']
})
export class DialogBoxComponent implements OnInit {

    action!: string;

    ngOnInit(): void {
    }

    constructor(
        public dialogRef: MatDialogRef<DialogBoxComponent>,
        //@Optional() is used to prevent error if no data is passed
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.action = this.data.action
    }

    doAction(map: Map<string, any>) {
        this.dialogRef.close({
            event: DialogActions.Update, data: {
                updatedValue: map
            }
        });
    }

    closeDialog() {
        debugger;
        this.dialogRef.close({event: DialogActions.Cancel});
    }
}
