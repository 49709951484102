<div *ngIf="action === 'Update'">

    <app-dialog-update [data]=data
                       (closedDialog)=closeDialog()
                       (updateElement)="doAction($event)">
    </app-dialog-update>

</div>

<div *ngIf="action === 'View'">
    <app-dialog-view-json [data]="data" (closedDialog)=closeDialog()></app-dialog-view-json>
</div>

<div *ngIf="action === 'ViewImage'">
    <app-dialog-view-image [data]="data" (closedDialog)="closeDialog()"></app-dialog-view-image>
</div>
