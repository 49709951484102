
<div *ngIf="advertisementItems">
    <table mat-table [dataSource]="advertisementItems" class="mat-elevation-z8 demo-table" #table>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id.</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="start-date">
            <th mat-header-cell *matHeaderCellDef> Start time</th>
            <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="end-date">
            <th mat-header-cell *matHeaderCellDef> End time</th>
            <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="alt">
            <th mat-header-cell *matHeaderCellDef> Alt</th>
            <td mat-cell *matCellDef="let element"> {{element.alt}} </td>
        </ng-container>

        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> Url</th>
            <td mat-cell *matCellDef="let element"> {{element.url}} </td>
        </ng-container>

        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef> Image</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button nbButton size="small" status="success" (click)="openDialog('ViewImage',element.image)">
                    Open Image
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="is-enabled">
            <th mat-header-cell *matHeaderCellDef> Is enabled</th>
            <td mat-cell *matCellDef="let element">
                {{element.isEnabled}}
            </td>
        </ng-container>

        <ng-container matColumnDef="placement">
            <th mat-header-cell *matHeaderCellDef>
                Placements
            </th>
            <td mat-cell *matCellDef="let element"> {{element.placements}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button nbButton size="small" status="danger" (click)="deleteAdvertisement(element.id)">
                    Delete
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button nbButton size="small" status="success" (click)="openDialog('Update',element)">
                    Edit
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
