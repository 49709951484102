import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnDto} from "../model/column.dto";
import {AdvertisementDto} from "../model/advertisement.dto";
import {MatDialog} from "@angular/material/dialog";
import {DialogBoxComponent} from "../dialog-box/dialog-box.component";
import {DialogActions} from "../model/dialog-actions.enum";

@Component({
    selector: 'app-advertisements-table',
    templateUrl: './advertisements-table.component.html',
    styleUrls: ['./advertisements-table.component.css']
})
export class AdvertisementsTableComponent implements OnInit {

    @Output() advertisementUpdate = new EventEmitter<AdvertisementDto>()
    @Output() advertisementDeleted = new EventEmitter<string>()
    @Input() advertisementItems: AdvertisementDto[] | undefined
    @Input() columns: ColumnDto[] | undefined

    displayedColumns: string[] = ['id', 'start-date', 'end-date', 'alt', 'url', 'image', 'is-enabled', 'placement', 'delete', 'action'];

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
    }


    openDialog(action: string, obj: AdvertisementDto) {
        const dialogRef = this.dialog.open(DialogBoxComponent, {
            width: '400px',
            height: '500px',
            data: {
                action,
                data: obj,
                columns: this.columns
            },
        });

        dialogRef.afterClosed()
            .subscribe(result => {
                    if (result.event == DialogActions.Update) {
                        this.updateRowData(
                            this.mapArrayToAdvertisement(result.data.updatedValue)
                        )
                    }
                }
            );
    }

    mapArrayToAdvertisement(elements: Map<string, any>): AdvertisementDto {
        return <AdvertisementDto>{
            id: elements.get("id"),
            startDate: elements.get("startDate"),
            endDate: elements.get("endDate"),
            image: elements.get("image"),
            url: elements.get("url"),
            isEnabled: elements.get("isEnabled"),
            placements: elements.get("placements"),
            alt: elements.get("alt")
        }
    }

    private updateRowData(row_obj: AdvertisementDto) {
        this.advertisementItems?.find(advertisement => {
            if (advertisement.id === row_obj.id) {
                const newAdvertisement = <AdvertisementDto>{...row_obj}
                this.advertisementUpdate.emit(newAdvertisement)
            }
        })
    }

    deleteAdvertisement(id: string) {
        this.advertisementDeleted.emit(id)
    }
}
