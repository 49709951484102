import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Pagination} from "../model/pagination";
import {Observable} from "rxjs";
import {MyHttpParam} from "../model/http-param";
import {FilterSortOptionsDto} from "../model/grid-column/filter-sort-options.dto";

@Injectable({
    providedIn: 'root'
})
export class PaginationService<T> {

    constructor(private httpClient: HttpClient) {
    }

    getPagination(url: string, parameters: MyHttpParam[], filterSortOptions?: FilterSortOptionsDto): Observable<Pagination<T>> {
        let params = new HttpParams();
        const limitParam = parameters.find(it => it.name == 'limit')
        if (limitParam == undefined)
            parameters.push({name: 'limit', value: 50})
        if (filterSortOptions) {
            if (filterSortOptions.sortOrder && filterSortOptions.sortColumn) {
                params = params.append('sortOrder', filterSortOptions.sortOrder)
                params = params.append('sortColumn', filterSortOptions.sortColumn)
            }

            filterSortOptions.filterOptions.forEach((filterSortOption, index) => {
                if (filterSortOption.filterValue && filterSortOption.filterColumn && filterSortOption.filterGrid) {
                    params = params.append(`filterValue[${index}]`, filterSortOption.filterValue)
                    params = params.append(`filterColumn[${index}]`, filterSortOption.filterColumn)
                    params = params.append(`filterGrid[${index}]`, filterSortOption.filterGrid)
                }
            })

        }

        parameters.forEach(it => params = params.append(it.name, it.value))
        return this.httpClient.get<Pagination<T>>(url, {params: params})
    }
}