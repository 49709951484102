import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TransformService {

  constructor() {
  }

  transformToMOBX(value: number): number {
    return value / Math.pow(10, Math.abs(9))
  }

  transformToNanoMOBX(value: number): number {
    return value * Math.pow(10, Math.abs(9))
  }
}
