import {Observable} from "rxjs";
import {ActionType} from "../../model/action-type";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ColumnDto} from "../../model/column.dto";
import {environment} from "../../../environments/environment";
import {Pagination} from "../../model/pagination";
import {PaginationService} from "../pagination.service";
import {MyHttpParam} from "../../model/http-param";

@Injectable({
  providedIn: 'root'
})
export class ActionTypeService {

  baseUrl = environment.apiUrl + "/actions"

  constructor(
      private paginationService: PaginationService<ActionType>,
      private httpClient: HttpClient) {
  }

  getColumns(): Observable<ColumnDto[]> {
    return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/columns`)
  }

  getActionTypesPagination(page: number): Observable<Pagination<ActionType>> {
    const params: MyHttpParam[] = [{name: 'page', value: page}]
    return this.paginationService.getPagination(`${this.baseUrl}/paginate`, params)
  }

  updateActionType(actionType: ActionType): Observable<ActionType> {
    return this.httpClient.patch<ActionType>(this.baseUrl, actionType)
  }
}
