import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ColumnDto} from "../../model/column.dto";
import {environment} from "../../../environments/environment";
import {Pagination} from "../../model/pagination";
import {DismissedActionDto} from "../../model/dismissed-action.dto";
import {MyHttpParam} from "../../model/http-param";
import {PaginationService} from "../pagination.service";

@Injectable({
    providedIn: 'root'
})
export class DismissedActionService {

    baseUrl = environment.apiUrl + "/actions/dismissed"

    constructor(
        private paginationService: PaginationService<DismissedActionDto>,
        private httpClient: HttpClient) {
    }

    getColumns(): Observable<ColumnDto[]> {
        return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/columns`)
    }

    getAdditionalDataColumns(): Observable<ColumnDto[]>{
        return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/data-columns`)
    }

    getDismissedActionsByUserId(page: number, userId: string): Observable<Pagination<DismissedActionDto>> {
        const params: MyHttpParam[] = [{name: 'page', value: page}, {name: 'userId', value: userId}]
        return this.paginationService.getPagination(`${this.baseUrl}/paginate`, params)
    }

}
