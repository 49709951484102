import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Epoch} from "../model/epoch";
import {MatTable} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {DialogBoxComponent} from "../dialog-box/dialog-box.component";
import {DialogActions} from "../model/dialog-actions.enum";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ColumnDto} from "../model/column.dto";
import {TransformService} from "../service/transform-mobix/transform.service";
import {saveAs as importedSaveAs} from "file-saver";
import {EpochService} from "../service/epochs/epoch.service";
import {switchMap} from 'rxjs';

@Component({
    selector: 'app-epoch-table',
    templateUrl: './epoch-table.component.html',
    styleUrls: ['./epoch-table.component.css']
})
export class EpochTableComponent implements OnInit {

    @Output() epochUpdate = new EventEmitter<Epoch>()
    @Output() epochDeleted = new EventEmitter<boolean>()
    @Input() epochItems: Epoch[] | undefined
    @Input() columns: ColumnDto[] | undefined

    epochForms: FormGroup = this.fb.group({
        elements: new FormArray([])
    })

    displayedColumns: string[] = ['id', 'start-time', 'end-time', 'reward-pool', 'download', 'user-stats', 'delete', 'action'];

    @ViewChild(MatTable, {static: true}) table: MatTable<any> | undefined;

    constructor(private dialog: MatDialog,
                private fb: FormBuilder,
                private transform: TransformService,
                private _epochService: EpochService) {
    }

    ngOnInit(): void {
    }

    openDialog(action: string, obj: Epoch) {
        const dialogRef = this.dialog.open(DialogBoxComponent, {
            width: '350px',
            height: '250px',
            data: {
                action,
                data: obj,
                columns: this.columns
            },
        });

        dialogRef.afterClosed().subscribe(result => {
                if (result.event == DialogActions.Update) {
                    this.updateRowData(this.mapArrayToEpoch(result.data.updatedValue))
                }
            }
        );
    }

    private updateRowData(row_obj: Epoch) {
        this.epochItems?.find(epoch => {
            if (epoch.id === row_obj.id) {
                epoch.rewardPool = row_obj.rewardPool
                this.epochUpdate.emit(epoch)
            }
        })
    }

    mapArrayToEpoch(elements: Map<string, any>): Epoch {
        return <Epoch>{
            id: elements.get("id"),
            startTime: elements.get("startTime"),
            endTime: elements.get("endTime"),
            rewardPool: elements.get("rewardPool")
        }
    }

    downloadEpochFile(epochId: number) {
        if (epochId)
            this._epochService.getEpochStatistics(epochId)
                .subscribe(blob => importedSaveAs(blob,
                    `epoch-statistics-${epochId}.csv`)
                )
    }

    downloadUserEpochFile(epochId: number) {
        if (epochId)
            this._epochService.getUserPerEpochStatistics(epochId)
                .subscribe(blob => importedSaveAs(blob,
                    `user-epoch-statistics-${epochId}.csv`)
                )
    }

    deleteEpoch(epochId: number) {
        if (epochId)
            this._epochService.deleteEpoch(epochId)
                .subscribe(() => {
                        this.epochDeleted.emit(true)
                    }
                )
    }

}
