<div class="container">
    <form class="item" (ngSubmit)="downloadEpochFile()" [formGroup]="epochStatisticsForm">
        <nb-card>
            <nb-card-header><h4>Epoch statistics</h4></nb-card-header>
            <nb-card-body>
                <input nbInput status="primary" formControlName="epochId" required placeholder="Epoch Id">
            </nb-card-body>
            <nb-card-footer>
                <button status="primary" nbButton>Submit</button>
            </nb-card-footer>
        </nb-card>
    </form>

    <form class="item" (ngSubmit)="downloadUserStatisticsFile()" [formGroup]="userStatisticsForm">
        <nb-card>
            <nb-card-header>
                <h3>
                    <div>User per epoch statistics</div>
                </h3>
            </nb-card-header>
            <nb-card-body>
                <div class="first-input">
                    <input nbInput status="primary" formControlName="epochId" required placeholder="Epoch Id">
                </div>
            </nb-card-body>
            <nb-card-footer>
                <button status="primary" nbButton>Submit</button>
            </nb-card-footer>
        </nb-card>
    </form>
</div>
