<div>
    <h1>Clear user actions data</h1>
</div>

<form [formGroup]="userForm">
    <div class="container">
        <nb-card>
            <nb-card-header class="align-text-center"><h4>User address</h4></nb-card-header>
            <nb-card-body>
                <div class="item">
                    <input nbInput class='full-width input-full-width' status="primary" formControlName="userAddress"
                           required
                           placeholder="User address">
                </div>
            </nb-card-body>

            <nb-card-footer>
                <div class="button-container">
                    <button class="left-align-button" status="primary" nbButton (click)="deleteUserTripActions()">
                        Delete trip actions
                    </button>
                    <button class="left-align-button" status="info" nbButton (click)="deleteUserReferralActions()">
                        Delete referral actions
                    </button>
                    <button class="left-align-button" status="success" nbButton (click)="deleteUserCaptchaActions()">
                        Delete captcha actions
                    </button>
                </div>
            </nb-card-footer>


            <nb-card-footer>
                <div class="button-container">
                    <button class="left-align-button" status="danger" nbButton (click)="deleteAllUserActions()">
                        Delete all actions
                    </button>
                </div>
            </nb-card-footer>

        </nb-card>
    </div>
</form>