import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClearUserActionsDataService} from "../service/clear-user-data/clear-user-actions-data.service";
import {AuthService} from "../service/auth/auth.service";

@Component({
    selector: 'app-clear-user-data',
    templateUrl: './clear-user-data.component.html',
    styleUrls: ['./clear-user-data.component.css']
})
export class ClearUserDataComponent implements OnInit {
    userRoles: string[] | null = []

    userForm = new FormGroup({
            userAddress: new FormControl('', [Validators.required])
        }
    )

    constructor(private _clearUserActionsDataService: ClearUserActionsDataService,
                private _authService: AuthService) {
    }

    ngOnInit(): void {
        this.userRoles = this._authService.getUserRoles()
    }

    deleteUserReferralActions() {
        this.hasUserAdminRole()

        this._clearUserActionsDataService
            .deleteUserReferralActionsData(this.userForm.value)
            .subscribe({
                    next: () => {
                        alert(`Referral actions for user with ${this.userForm.value.userAddress}  have been deleted`)
                        this.userForm.reset()
                    },
                    error: err => {
                        alert(err.error.message)
                    }
                }
            )
    }

    deleteUserTripActions() {
        this.hasUserAdminRole()

        this._clearUserActionsDataService
            .deleteUserTripActionsData(this.userForm.value)
            .subscribe(
                {
                    next: () => {
                        alert(`Trip actions for user with ${this.userForm.value.userAddress}  have been deleted`)
                        this.userForm.reset()
                    },
                    error: err => {
                        alert(err.error.message)
                    }
                }
            )
    }

    deleteUserCaptchaActions() {
        this.hasUserAdminRole()

        this._clearUserActionsDataService
            .deleteUserCaptchaActionsData(this.userForm.value)
            .subscribe({
                next: () => {
                    alert(`Captcha actions for user with ${this.userForm.value.userAddress}  have been deleted`)
                    this.userForm.reset()
                },
                error: err => {
                    alert(err.error.message)
                }
            })
    }

    deleteAllUserActions() {
        this.hasUserAdminRole()

        this._clearUserActionsDataService
            .deleteAllUserActionsData(this.userForm.value)
            .subscribe({
                next: () => {
                    alert(`Trip, referral and captcha actions for user with ${this.userForm.value.userAddress}  have been deleted`)
                    this.userForm.reset()
                },
                error: err => {
                    alert(err.error.message)
                }
            })
    }

    hasUserAdminRole(){
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }
    }

}
