
<form [formGroup]="form" (submit)="submit()">
    <nb-card>
        <nb-card-body>
            <h2>Register</h2>
            <p>Please enter your login and password!</p>
            <input nbInput formControlName="username" placeholder="Username" type="text"/> <br>
            <input nbInput formControlName="password" placeholder="Password" type="password"/> <br>
            <input nbInput type="text" formControlName="role" name="role" list="role">
            <datalist id="role">
                <option value="ADMIN">
                <option value="MANAGER">
            </datalist>
            <br>
            <button mat-stroked-button class="no-hover-effect" type="submit">Login</button>
        </nb-card-body>
    </nb-card>
</form>