import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Epoch} from "../../model/epoch";
import {ColumnDto} from "../../model/column.dto";
import {TransformService} from "../transform-mobix/transform.service";
import {environment} from "../../../environments/environment";
import {Pagination} from "../../model/pagination";
import {MyHttpParam} from "../../model/http-param";
import {PaginationService} from "../pagination.service";

@Injectable({
    providedIn: 'root'
})
export class EpochService {

    baseUrl = environment.apiUrl + "/epochs"

    constructor(
        private paginationService: PaginationService<Epoch>,
        private httpClient: HttpClient,
        private transform: TransformService) {
    }

    getColumns(): Observable<ColumnDto[]> {
        return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/columns`)
    }

    getEpochsPagination(page: number): Observable<Pagination<Epoch>> {
        const params: MyHttpParam[] = [{name: 'page', value: page}]
        return this.paginationService.getPagination(`${this.baseUrl}/paginate`, params)
    }

    updateEpoch(epoch: Epoch): Observable<Epoch> {
        const epochDto: Epoch = {
            id: epoch.id,
            rewardPool: this.transform.transformToNanoMOBX(epoch.rewardPool),
            startTime: epoch.startTime,
            endTime: epoch.endTime
        }

        return this.httpClient.patch<Epoch>(this.baseUrl, epochDto)
    }

    getEpochStatistics(epochId: number): Observable<Blob> {
        return this.httpClient.get(`${this.baseUrl}/epoch-statistics/${epochId}`, {responseType: 'blob'})
    }

    getUserPerEpochStatistics(epochId: number): Observable<Blob> {
        return this.httpClient.get(`${this.baseUrl}/epoch-user-statistics/${epochId}`, {responseType: 'blob'})
    }

    deleteEpoch(epochId:number): Observable<Object>{
        return this.httpClient.delete(`${this.baseUrl}/${epochId}`)
    }
}
