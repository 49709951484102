import {Component, OnInit} from '@angular/core';
import {AuthService} from "./service/auth/auth.service";
import {Router} from "@angular/router";
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    environment = environment.title

    username: string = ''

    isLoggedIn: boolean = false
    selectedTab: string = "Epoch";

    constructor(private _authService: AuthService, private router: Router) {
    }

    logOut() {
        this._authService.logout()

        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate(['/login']));

        this.ngOnInit()
    }

    ngOnInit(): void {
        this.username = this._authService.getUsername()
        console.log('Selected tab ->' + this.selectedTab)
        this._authService.loggedIn()
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn
                if (isLoggedIn) {
                    this.username = this._authService.getUsername()
                }
            })
    }
}