import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PagerService} from "../pager.service";

@Component({
    selector: 'app-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {

    pager: any = {};
    limit: number = 50
    _totalItems: number | undefined

    @Input() set totalItems(value: number | undefined) {
        this._totalItems = value;
        if (this._totalItems != undefined) {
            this.pager = this._pagerService.getPager(this._totalItems, 1, this.limit);
        }
    }

    @Output() newItemEvent = new EventEmitter<number>();

    constructor(private _pagerService: PagerService) {
    }

    ngOnInit(): void {
        if (this._totalItems != undefined) {
            this.pager = this._pagerService.getPager(this._totalItems, 1, this.limit);
        }
    }

    setPage(page: number) {
        if (page < 1 || page > this.pager.totalPages || this._totalItems == undefined) {
            return;
        }

        // get pager object from service
        this.pager = this._pagerService.getPager(this._totalItems, page, this.limit);
        this.newItemEvent.emit(page);
    }

}
