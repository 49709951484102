<form [formGroup]="formGroup" class="full-res" (ngSubmit)="doAction()">

    <div mat-dialog-content>
        <div class="full-res">
            <div formArrayName="elements">
                <div *ngFor="let element of elements.controls; let i = index">
                    <mat-form-field class="large-input-width" *ngIf="getColumnType(i) == 'text'">
                        <input [formControlName]="i" matInput class="input-width-increase" type="text">
                    </mat-form-field>

                    <mat-form-field class="large-input-width" *ngIf="getColumnType(i) == 'large-text'">
                        <textarea class="large-input-width" [formControlName]="i" matInput cols="10"
                                  rows="10"> </textarea>
                    </mat-form-field>

                    <mat-form-field class="json-box-editor" *ngIf="getColumnType(i) == 'json'">
                        <json-editor [formControlName]="i" [options]="jsonEditorOptions"></json-editor>
                    </mat-form-field>

                    <mat-form-field class="input-width-increase" *ngIf="getColumnType(i) == 'integer'">
                        <input [formControlName]="i" matInput class="input-width-increase" type="number">
                    </mat-form-field>

                    <mat-form-field class="input-width-increase" *ngIf="getColumnType(i) == 'date'">
                        <input [formControlName]="i" matInput class="input-width-increase" [matDatepicker]="picker">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <div class="input-width-increase" *ngIf="getColumnType(i) == 'file'">
                        <button type="button" nbButton (click)="imgFileInput.click()">Add a photo</button>
                        <input hidden type="file" #imgFileInput (change)="onFileChange($event,i)"/>
                    </div>

                    <div class="input-width-increase" *ngIf="getColumnType(i) == 'boolean'">
                        <mat-checkbox class="example-margin"  [formControlName]="i">
                            Enabled
                        </mat-checkbox>
                    </div>

                    <mat-form-field class="input-width-increase" *ngIf="getColumnType(i) == 'select'" appearance="fill">
                        <mat-select [formControlName]='i' multiple>
                            <mat-option *ngFor='let option of getColumnValue(i)'
                                        [value]='option'>
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button type="submit">Update</button>
        <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
    </div>
</form>