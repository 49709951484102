
<div *ngIf="epochItems">

  <table mat-table [dataSource]="epochItems" class="mat-elevation-z8 demo-table" #table>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id. </th>
      <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="start-time">
      <th mat-header-cell *matHeaderCellDef> Start time </th>
      <td mat-cell *matCellDef="let element"> {{element.startTime | date: 'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="end-time">
      <th mat-header-cell *matHeaderCellDef> End time </th>
      <td mat-cell *matCellDef="let element"> {{element.endTime | date: 'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="reward-pool">
      <th mat-header-cell *matHeaderCellDef> Reward pool </th>
      <td mat-cell *matCellDef="let element"> {{element.rewardPool}} </td>
    </ng-container>

    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef> Download </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button nbButton size="small" status="primary" (click)="downloadEpochFile(element.id)">Download</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="user-stats">
      <th mat-header-cell *matHeaderCellDef> User stats </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button nbButton size="small" status="primary" (click)="downloadUserEpochFile(element.id)">Download</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button nbButton size="small" status="danger" (click)="deleteEpoch(element.id)">Delete</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button nbButton size="small" status="success" (click)="openDialog('Update',element)">Edit</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

