import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Placement} from "../model/enum/placement.enum";
import {AdvertisementDto} from "../model/advertisement.dto";

@Component({
    selector: 'app-create-advertisement',
    templateUrl: './create-advertisement.component.html',
    styleUrls: ['./create-advertisement.component.css']
})
export class CreateAdvertisementComponent implements OnInit {
    @Output() advertisementCreated = new EventEmitter<AdvertisementDto>()

    constructor() {
    }

    faqForm = new FormGroup({
        startDate: new FormControl(''),
        endDate: new FormControl(''),
        alt: new FormControl(''),
        url: new FormControl(''),
        image: new FormControl(''),
        isEnabled: new FormControl(''),
        placements: new FormControl('')
    });

    ngOnInit(): void {
    }

    createAdvertisement() {
        this.advertisementCreated.emit(this.faqForm.value)
        this.faqForm.reset()
    }

    getPlacementTypes() {
        return Object.values(Placement.PlacementEnum)
    }

    onFileChange($event: any) {
        const file = $event?.target?.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = () => {
            if(reader.result && reader.result !== '') {
                this.faqForm.get('image')?.setValue(reader.result)
                alert('Image has been set')
            }
            else{
                alert('Image has not been properly set, try again,')
            }
        }
    }
}
