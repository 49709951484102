<nb-layout xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">

    <nb-layout-header>


        <div class="header">
            <a [routerLink]="['']">
                <h1 class="larger-header">MOBIX</h1>
            </a>
        </div>


        <div class="right-header">
            <div *ngIf="isLoggedIn">
                <span class='user-name'>Welcome {{username}}</span>
                <button mat-stroked-button class="no-hover-effect" *ngIf="isLoggedIn" (click)="logOut()">Logout</button>
            </div>
        </div>

    </nb-layout-header>
    <nb-sidebar *ngIf="isLoggedIn" state="expanded" >
        <nb-select placeholder="Select..." [(selected)]="selectedTab">
            <nb-option value="Epochs" [routerLink]="['/epochs']">
                <h5>Epochs</h5>
            </nb-option>
            <nb-option value="Actions" [routerLink]="['/actions']">
                    <h5>Actions</h5>
            </nb-option>
            <nb-option value="Faq" [routerLink]="['/faq']">
                    <h5>FAQ</h5>
            </nb-option>
            <nb-option value="Issues" [routerLink]="['/issues']">
                    <h5>Issues</h5>
            </nb-option>
            <nb-option value="Config" [routerLink]="['/config']">
                    <h5>Configs</h5>
            </nb-option>
            <nb-option value="Dismissed actions" [routerLink]="['/dismissed-actions']">
                    <h5>Dismissed Actions</h5>
            </nb-option>
            <nb-option value="Register" [routerLink]="['/register']">
                    <h5>Register</h5>
            </nb-option>
            <nb-option value="Statistics" [routerLink]="['/statistics']">
                    <h5>Statistics</h5>
            </nb-option>
            <nb-option value="Notifications" [routerLink]="['/notifications']">
                    <h5>Notifications</h5>
            </nb-option>
            <nb-option value="Reward Code" [routerLink]="['/reward-code']">
                <h5>Reward Code</h5>
            </nb-option>
            <nb-option value="Clear user data" [routerLink]="['/clear-user-data']">
                <h5>Clear Data</h5>
            </nb-option>
            <nb-option value="Advertisements" [routerLink]="['/advertisements']">
                <h5>Advertisements</h5>
            </nb-option>
        </nb-select>
    </nb-sidebar>

    <nb-layout-column>
        <router-outlet></router-outlet>
    </nb-layout-column>

    <nb-layout-footer fixed>
        <p class="horizontal_line" [ngClass]="{'local': this.environment === 'local', 'dev': this.environment === 'dev', 'stage': this.environment === 'stage', 'prod': this.environment === 'prod'}">{{this.environment | uppercase}}</p>
    </nb-layout-footer>
</nb-layout>