<div>
  <h1>Configuration</h1>
</div>

<div *ngIf="configPagination">

  <table mat-table [dataSource]="configPagination.items" class="mat-elevation-z8 demo-table" #table>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button nbButton size="small" status="success" (click)="openDialog('Update',element)">Edit</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <app-pager
          [totalItems]="configPagination?.meta?.totalItems"
          (newItemEvent)="getIssuePaginate($event)">
  </app-pager>
</div>

