import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../service/auth/auth.service";
import {Router} from "@angular/router";


@Component({
    selector: 'app-auth',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    form: FormGroup = this.formBuilder.group({
        username: '',
        password: ''
    })

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    submit(): void {
        this.authService.login(this.form?.getRawValue()).subscribe({
            next: () => {
                this.router.navigate(['/'])
            },
            error: error => {
                alert(error.error.message)
            }
        })
    }


}
