import {Component, OnInit} from '@angular/core';
import {FaqService} from "../service/faq/faq.service";
import {MatDialog} from "@angular/material/dialog";
import {Faq} from "../model/faq";
import {DialogBoxComponent} from "../dialog-box/dialog-box.component";
import {zip} from "rxjs";
import {ColumnDto} from "../model/column.dto";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

    userRoles: string[] | null = []
    faqsPagination: Pagination<Faq> = <Pagination<Faq>>{}
    columns: ColumnDto[] = []

    pager: any = {};

    displayedColumns: string[] = ['title', 'answer', 'order', 'delete', 'action'];

    faqForm = new FormGroup({
        title: new FormControl(''),
        answer: new FormControl(''),
        order: new FormControl('')
    });

    constructor(private _faqService: FaqService,
                private dialog: MatDialog,
                private _authService: AuthService) {
    }

    ngOnInit(): void {
        this.init()
    }

    init() {
        zip(
            this._faqService.getFaqPagination(1),
            this._faqService.getColumns()
        ).subscribe(([faqs, columns]) => {
            this.faqsPagination = faqs
            this.columns = columns
        })
        this.userRoles = this._authService.getUserRoles()
    }

    getFaqPagination($event: number) {
        this._faqService.getFaqPagination($event)
            .subscribe(it => {
                this.faqsPagination = it
            })
    }

    openDialog(action: string, obj: any) {
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }

        const dialogRef = this.dialog.open(DialogBoxComponent, {
            width: '450px',
            height: '480px',
            data: {
                data: obj,
                columns: this.columns,
                action
            }
        });

        dialogRef.afterClosed()
            .subscribe(result => {
                    if (!this.userRoles?.some(value => value == 'ADMIN')) {
                        alert("You do not have admin privileges.")
                        return
                    }

                    if (result.event == 'Update') {
                        this.updateRowData(this.mapArrayToFaq(result.data.updatedValue))
                        this._faqService.updateFaq(this.mapArrayToFaq(result.data.updatedValue)).subscribe()
                    }
                }
            );
    }

    private updateRowData(row_obj: Faq) {
        this.faqsPagination.items = this.faqsPagination.items?.filter(
            (value) => {
                if (value.id == row_obj.id) {
                    value.title = row_obj.title
                    value.answer = row_obj.answer
                    value.order = row_obj.order
                }
                return true
            })
    }

    deleteFaq(faqId: number) {
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }

        this._faqService.deleteFaq(faqId)
            .subscribe(() => this.init())
    }

    mapArrayToFaq(elements: Map<string, any>): Faq {
        return <Faq>{
            id: elements.get("id"),
            answer: elements.get("answer"),
            order: elements.get("order"),
            title: elements.get("title")
        }
    }

    createFaq() {
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }

        this.faqForm.value.order = ++this.faqsPagination.items.length
        this._faqService.createFaq(this.faqForm.value)
            .subscribe(() => {
                    alert('You have created a new FAQ')
                    this.init()
                    this.faqForm.reset()
                }
            )
    }

}
