<div>
    <h1>Advertisements</h1>
</div>

<app-create-advertisement (advertisementCreated)="createAdvertisement($event)">
</app-create-advertisement>


<div *ngIf="advertisements">
    <app-advertisements-table [advertisementItems]="advertisements"
                              [columns]="columns"
                              (advertisementDeleted)="deleteAdvertisementById($event)"
                              (advertisementUpdate)="updateAdvertisement($event)">
    </app-advertisements-table>
</div>