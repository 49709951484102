import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {map, Observable, take} from "rxjs";
import {AuthService} from "./auth.service";
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.loggedIn().pipe(
            take(1)
        ).pipe(
            map(it => {
                if (!it)
                    this.router.navigate(['/login'])
                return it
            })
        )
    }

}
