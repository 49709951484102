import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ColumnDto} from "../model/column.dto";
import {JsonEditorOptions} from "ang-jsoneditor";
import {Placement} from "../model/enum/placement.enum";

@Component({
    selector: 'app-dialog-update',
    templateUrl: './dialog-update.component.html',
    styleUrls: ['./dialog-update.component.css']
})
export class DialogUpdateComponent implements OnInit {

    @Input() data: any
    @Output() closedDialog = new EventEmitter<void>();
    @Output() updateElement = new EventEmitter<Map<string, any>>()

    formGroup: FormGroup = this._formBuilder.group({
        elements: new FormArray([])
    })
    uneditableFormGroup: FormGroup = this._formBuilder.group({
        uneditableElements: new FormArray([])
    })

    columns: ColumnDto[] = []
    uneditableColumns: ColumnDto[] = []

    jsonEditorOptions!: JsonEditorOptions;

    constructor(private _formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.columns = (this.data.columns as ColumnDto[]).filter(it => it.isEditable)
        this.columns.forEach(col => {
            this.elements.push(this._formBuilder.control(this.data.data[col.columnName], Validators.required))
        })

        this.uneditableColumns = (this.data.columns as ColumnDto[]).filter(it => !it.isEditable)
        this.uneditableColumns.forEach(col => {
            this.uneditableElements.push(this._formBuilder.control(this.data.data[col.columnName]))
        })

        this.jsonEditorOptions = new JsonEditorOptions()
        this.jsonEditorOptions.mode = 'form'// set form allowed so that keys are not editable.
    }

    get elements() {
        return this.formGroup.get('elements') as FormArray;
    }

    get uneditableElements() {
        return this.uneditableFormGroup.get('uneditableElements') as FormArray;
    }

    getColumnType(i: number): string {
        return this.columns[i].type
    }

    getColumnValue(i: number) {
        if (Object.values(Placement.PlacementEnum).includes(this.elements.value[i][0]))
            return Object.values(Placement.PlacementEnum)

        return Object.values(Placement.PlacementEnum)
    }

    closeDialog() {
        this.closedDialog.emit()
    }

    doAction() {
        const map = new Map<string, any>()

        this.columns.forEach(
            (it, idx) =>
                map.set(it.columnName, this.elements.at(idx).value)
        )

        this.uneditableColumns.forEach(
            (it, idx) =>
                map.set(it.columnName, this.uneditableElements.at(idx).value)
        )

        this.updateElement.emit(map)
    }

    onFileChange($event: any, i: number) {
        const file = $event?.target?.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.elements.at(i).setValue(reader.result)
            alert('Image has been set')
        }
    }

}
