<form [formGroup]="form" (submit)="submit()">
  <nb-card>
    <nb-card-body>
      <h2>Login</h2>
      <p>Please enter your login and password!</p>
      <input matInput formControlName="username" placeholder="Username" type="text"/> <br>
      <input matInput formControlName="password" placeholder="Password" type="password"/> <br>
      <button mat-stroked-button class="no-hover-effect" type="submit">Login</button>
    </nb-card-body>
  </nb-card>
</form>
