<form (ngSubmit)="createAdvertisement()" [formGroup]="faqForm">
    <nb-card>
        <nb-card-header class="align-text-center">
            <h4>Advertisements form</h4>
        </nb-card-header>
        <nb-card-body>
            <div class="item">
                <input nbInput class='full-width' status="primary" formControlName="alt" required placeholder="Alt">
            </div>
            <div class="item">
                <input nbInput class='full-width' status="primary" formControlName="url" required
                       placeholder="Url">
            </div>
            <div class="item">
                <button type="button" nbButton (click)="imgFileInput.click()">Add a photo</button>
                <input hidden type="file" #imgFileInput (change)="onFileChange($event)"/>
            </div>

            <div class="item">
                <nb-checkbox class='full-width' formControlName="isEnabled">
                    Enabled
                </nb-checkbox>
            </div>

            <div class="item">
                <nb-select formControlName="placements" multiple placeholder="Multiple Select">
                    <nb-option *ngFor="let option of getPlacementTypes()" [value]="option">
                        {{option}}
                    </nb-option>
                </nb-select>
            </div>

            <div class="item">
                <input nbInput formControlName="startDate" class="input-width-increase" placeholder="Start date"
                       [nbDatepicker]="startDatePicker">
                <nb-datepicker #startDatePicker></nb-datepicker>
            </div>

            <div class="item">
                <input nbInput formControlName="endDate" class="input-width-increase" placeholder="End Date"
                       [nbDatepicker]="endDatePicker">
                <nb-datepicker #endDatePicker></nb-datepicker>
            </div>
        </nb-card-body>

        <nb-card-footer>
            <div class="full-width center-button">
                <button class="left-align-button" status="primary" nbButton>Submit</button>
            </div>
        </nb-card-footer>

    </nb-card>
</form>
