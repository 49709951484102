<form (ngSubmit)="submitNotificationTopic()" [formGroup]="notificationTopicForm">
    <nb-card>
        <nb-card-header class="align-text-center"><h4>Notification topic</h4></nb-card-header>
        <nb-card-body>
            <div class="item">
                <input nbInput class='full-width' status="primary" formControlName="title" required placeholder="Title">
            </div>
            <div class="item">
                <textarea nbInput class='full-width' status="primary" formControlName="message" required
                          placeholder="Message"></textarea>
            </div>
        </nb-card-body>

        <nb-card-footer>
            <button class="left-align-button" status="primary" nbButton>Submit</button>
        </nb-card-footer>

    </nb-card>
</form>