<div>
    <h1>Issues</h1>
</div>

<div *ngIf="issuePagination">
    <button mat-button (click)="clearFilters()">Clear Filters</button>
    <table mat-table [dataSource]="issuePagination.items"
           class="mat-elevation-z8 demo-table" #table>

        <div *ngFor="let column of gridColumn">
            <ng-container matColumnDef="{{column._columnName}}">
                <th mat-header-cell *matHeaderCellDef>
                    <div style="white-space: nowrap">
                        {{column._prettyColumnName}}
                    </div>
                    <img *ngIf="column._isSortable" style="cursor: pointer"
                         (click)="sortByColumn(column._columnName)"
                         src="assets/images/sort.png" height="15" width="15">
                    <img *ngIf="column._isFilterable" style="cursor: pointer"
                         [matMenuTriggerFor]="menu" #menuTrigger
                         src="assets/images/search.png" height="15" width="15">

                    <!--                    filter menu-->
                    <mat-menu #menu="matMenu">
                        <div style="padding: 5px">
                            <input (click)="$event.stopPropagation();" [(ngModel)]="filters[column._columnName]"
                                   placeholder="*{{column._prettyColumnName}}">
                            <br>
                            <select (click)="$event.stopPropagation();" [(ngModel)]="selectedValue[column._columnName]">
                                <option *ngFor="let c of column._type.filters"
                                        [ngValue]="c">{{c}}</option>
                            </select>
                            <button mat-menu-item (click)="applyFilter()">Apply Filter</button>
                        </div>
                    </mat-menu>
                </th>
                <td mat-cell *matCellDef="let element">
<!--                    the following code is specific for this table-->
                    <div *ngIf="column._type.type === GridColumnTypeEnum.BOOLEAN">
                        <mat-checkbox [checked]="element[column._columnName]" (change)="solveIssue(element.id, $event)"></mat-checkbox>
                    </div>
                    <div *ngIf="column._type.type !== GridColumnTypeEnum.IMAGE">
                        <img src="{{element.image}}" alt="">
                    </div>
                    <div *ngIf="column._type.type !== GridColumnTypeEnum.BOOLEAN">
                        {{element[column._columnName]}}
                    </div>
                </td>

            </ng-container>
        </div>

        <tr mat-header-row *matHeaderRowDef="gridColumnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: gridColumnNames;"></tr>
    </table>

    <app-pager
            [totalItems]="issuePagination?.meta?.totalItems"
            (newItemEvent)="getIssuePaginate($event)">
    </app-pager>
</div>

