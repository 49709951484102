import {Pipe, PipeTransform} from "@angular/core";


@Pipe({name : 'nanoToMobx'})

export class TransformFromNanoToMobxPipe implements PipeTransform{

  transform(value: any, ...args: any[]): any {
      return value * Math.pow(10, Math.abs(9))
  }

}
