<div>
    <h1>Epochs</h1>
</div>

<div *ngIf="epochPagination">
    <app-epoch-table [epochItems]="epochPagination.items"
                     [columns]="columns"
                     (epochUpdate)="updateEpoch($event)"
                     (epochDeleted)="deletedEpoch($event)">
    </app-epoch-table>
    <app-pager
            [totalItems]="epochPagination?.meta?.totalItems"
            (newItemEvent)="getEpochPaginate($event)">
    </app-pager>
</div>


