import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ColumnDto} from "../../model/column.dto";
import {Config} from "../../model/config";
import {environment} from "../../../environments/environment";
import {Pagination} from "../../model/pagination";
import {PaginationService} from "../pagination.service";
import {MyHttpParam} from "../../model/http-param";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  baseUrl = environment.apiUrl + "/configs"

  constructor(
      private paginationService: PaginationService<Config>,
      private httpClient: HttpClient) { }

  getColumns(): Observable<ColumnDto[]> {
    return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/columns`)
  }

  getConfigPagination(page: number): Observable<Pagination<Config>> {
    const params: MyHttpParam[] = [{name: 'page', value: page}]
    return this.paginationService.getPagination(this.baseUrl, params)
  }

  updateConfig(config: Config): Observable<Config> {
    return this.httpClient.patch<Config>(this.baseUrl, config)
  }

}
