import {Component, OnInit} from '@angular/core';
import {DismissedActionService} from "../service/dimissed-actions/dismissed-action.service";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";
import {ColumnDto} from "../model/column.dto";
import {DismissedActionDto} from "../model/dismissed-action.dto";
import {DialogBoxComponent} from "../dialog-box/dialog-box.component";

@Component({
    selector: 'app-dismissed-actions',
    templateUrl: './dismissed-actions.component.html',
    styleUrls: ['./dismissed-actions.component.css']
})
export class DismissedActionsComponent implements OnInit {

    userId: string = ''
    userRoles: string[] | null = []
    dismissedActionsPagination: Pagination<DismissedActionDto> = <Pagination<DismissedActionDto>>{}
    columns: ColumnDto[] = []

    pager: any = {};

    displayedColumns: string[] = ['id', 'userId', 'deviceId', 'actionType', 'data', 'timeCreated'];

    constructor(
        private _dismissedActionService: DismissedActionService,
        private dialog: MatDialog,
        private _authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this._dismissedActionService.getColumns().subscribe(
            columns => {
                this.columns = columns
            })
    }

    openDialog(action: string, obj: any) {
        this.dialog.open(DialogBoxComponent, {
            width: '350px',
            height: '500px',
            data: {
                action,
                data: obj,
                columns: this.columns
            },
        });
    }

    getDismissedActionsPagination($event: number) {
        this._dismissedActionService.getDismissedActionsByUserId($event, this.userId)
            .subscribe(it => {
                this.dismissedActionsPagination = it
            })

    }

    updateAndSearchByUserId(userId: string) {
        this.userId = userId
        this.getDismissedActionsPagination(1)
    }
}
