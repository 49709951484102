<form (ngSubmit)="generateRewardCode()" [formGroup]="rewardCodeForm">
    <nb-card>
        <nb-card-header class="align-text-center"><h4>Generate Reward Code</h4></nb-card-header>
        <nb-card-body>
            <div class="item">
                <input nbInput formControlName="amount" placeholder="Amount" type="number" [min]="0">
            </div>
            <div class="item">
                <nb-checkbox formControlName="defaultAmount">Default</nb-checkbox>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button class="left-align-button" status="primary" nbButton>Submit</button>
        </nb-card-footer>
    </nb-card>
</form>

<nb-card *ngIf="rewardQrCode">
    <nb-card-header class="align-text-center"><h4>Reward QR Code</h4></nb-card-header>
    <nb-card-body>
        <qrcode [qrdata]="rewardQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </nb-card-body>
</nb-card>