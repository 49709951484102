import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NotificationTopicRequestDto} from "../../model/notification-topic-request.dto";
import {ClearUserActionsDataDto} from "../../model/clear-user-actions-data.dto";

@Injectable({
    providedIn: 'root'
})
export class ClearUserActionsDataService {
    baseUrl = environment.apiUrl + "/clear-user-data"

    constructor(private httpClient: HttpClient) {
    }

    deleteUserTripActionsData(clearUserActionsDto: ClearUserActionsDataDto) {
        return this.httpClient.request('delete', `${this.baseUrl}/trips`,
            {body: clearUserActionsDto}
        )
    }

    deleteUserReferralActionsData(clearUserActionsDto: ClearUserActionsDataDto) {
        return this.httpClient.request('delete', `${this.baseUrl}/referrals`,
            {body: clearUserActionsDto}
        )
    }

    deleteUserCaptchaActionsData(clearUserActionsDto: ClearUserActionsDataDto) {
        return this.httpClient.request('delete', `${this.baseUrl}/hcaptcha`,
            {body: clearUserActionsDto}
        )
    }

    deleteAllUserActionsData(clearUserActionsDto: ClearUserActionsDataDto) {
        return this.httpClient.request('delete', this.baseUrl,
            {body: clearUserActionsDto}
        )
    }

}


