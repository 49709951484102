import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Issue} from "../../model/issue";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Pagination} from "../../model/pagination";
import {PaginationService} from "../pagination.service";
import {MyHttpParam} from "../../model/http-param";
import {FilterSortOptionsDto} from "../../model/grid-column/filter-sort-options.dto";
import {GridColumn} from "../../model/grid-column/grid-column.dto";

@Injectable({
    providedIn: 'root'
})
export class IssueService {

    baseUrl = environment.apiUrl + '/issues'

    constructor(
        private paginationService: PaginationService<Issue>,
        private httpClient: HttpClient) {
    }

    getIssuesPagination(page: number, filterSortOptions: FilterSortOptionsDto): Observable<Pagination<Issue>> {
        const params: MyHttpParam[] = [{
            name: 'page',
            value: page,
        }]
        return this.paginationService.getPagination(this.baseUrl, params, filterSortOptions)
    }

    setIssueStatus(issueId: string, isSolved: boolean): Observable<Issue> {
        return this.httpClient.patch<Issue>(this.baseUrl, {issueId: issueId, isSolved: isSolved})
    }

    getGridColumns(): Observable<GridColumn[]> {
        return this.httpClient.get<GridColumn[]>(this.baseUrl + '/columns')
    }
}
