<div>
  <h1>Action Types</h1>
</div>

<div *ngIf="actionTypePagination">

  <table mat-table [dataSource]="actionTypePagination.items" class="mat-elevation-z8 demo-table" #table>

    <ng-container matColumnDef="methodId">
      <th mat-header-cell *matHeaderCellDef>Id. </th>
      <td mat-cell *matCellDef="let element"> {{element.methodId}}</td>
    </ng-container>

    <ng-container matColumnDef="methodDescription">
      <th mat-header-cell *matHeaderCellDef> Method description </th>
      <td mat-cell *matCellDef="let element"> {{element.methodDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="rewardPayment">
      <th mat-header-cell *matHeaderCellDef> Reward payment </th>
      <td mat-cell *matCellDef="let element"> {{element.rewardPayment | json}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button nbButton size="small" status="success" (click)="openDialog('Update',element)">Edit</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <app-pager
          [totalItems]="actionTypePagination?.meta?.totalItems"
          (newItemEvent)="getEpochPaginate($event)">
  </app-pager>
</div>

