import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EpochsComponent} from './epochs/epochs.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {
    NB_TIME_PICKER_CONFIG,
    NbButtonModule,
    NbCardModule, NbCheckboxModule, NbDatepickerModule, NbTimepickerModule,
    NbInputModule,
    NbLayoutModule,
    NbMenuModule, NbSelectModule,
    NbSidebarModule,
    NbThemeModule
} from "@nebular/theme";
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {EpochTableComponent} from './epoch-table/epoch-table.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {DialogBoxComponent} from './dialog-box/dialog-box.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ActionTypesComponent} from "./action-types/action-types.component";
import {NgJsonEditorModule} from 'ang-jsoneditor'
import {FaqComponent} from './faq/faq.component';
import {IssueComponent} from './issue/issue.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {LoginComponent} from './login/login.component';
import {ConfigComponent} from './config/config.component';
import {TransformFromNanoToMobxPipe} from "./pipes/transform-from-nano-to-mobx.pipe";
import {RegisterComponent} from './register/register.component';
import {AuthInterceptor} from "./interceptors/auth-interceptor.component";
import {NotificationsComponent} from "./notifications/notifications.component";
import {StatisticsComponent} from './statistics/statistics.component';
import {PagerService} from "./pager.service";
import {PagerComponent} from './pager/pager.component';
import {DismissedActionsComponent} from './dismissed-actions/dismissed-actions.component';
import {DialogUpdateComponent} from './dialog-update/dialog-update.component';
import {DialogViewJsonComponent} from './dialog-view-json/dialog-view-json.component';
import {RewardCodeComponent} from "./reward-code/reward-code.component";
import {QRCodeModule} from "angularx-qrcode";
import {ClearUserDataComponent} from './clear-user-data/clear-user-data.component';
import {AdvertisementsComponent} from './advertisements/advertisements.component';
import {AdvertisementsTableComponent} from './advertisements-table/advertisements-table.component';
import {CreateAdvertisementComponent} from './create-advertisement/create-advertisement.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {DialogViewImageComponent} from './dialog-view-image/dialog-view-image.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
    declarations: [
        AppComponent,
        EpochsComponent,
        EpochTableComponent,
        DialogBoxComponent,
        ActionTypesComponent,
        FaqComponent,
        NotificationsComponent,
        IssueComponent,
        LoginComponent,
        ConfigComponent,
        TransformFromNanoToMobxPipe,
        RegisterComponent,
        StatisticsComponent,
        PagerComponent,
        DismissedActionsComponent,
        DialogUpdateComponent,
        DialogViewJsonComponent,
        RewardCodeComponent,
        ClearUserDataComponent,
        AdvertisementsComponent,
        AdvertisementsTableComponent,
        CreateAdvertisementComponent,
        DialogViewImageComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NbInputModule,
        HttpClientModule,
        NbLayoutModule,
        NbThemeModule.forRoot({name: 'dark'}),
        NbMenuModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbEvaIconsModule,
        NbMenuModule,
        NgJsonEditorModule,
        ReactiveFormsModule,
        MatTableModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        NbCardModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        NbButtonModule,
        NbSelectModule,
        MatNativeDateModule,
        NbCheckboxModule,
        QRCodeModule,
        MatDatepickerModule,
        MatRadioModule,
        MatSelectModule,
        NbDatepickerModule.forRoot(),
        NbTimepickerModule.forRoot(),
        MatMenuModule
    ],
    providers: [
        PagerService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: NB_TIME_PICKER_CONFIG, useValue: {}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
