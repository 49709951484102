import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from "../service/notifications/notifications.service";
import {AuthService} from "../service/auth/auth.service";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
    userRoles: string[] | null = []

    notificationTopicForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        message: new FormControl('', [Validators.required])
    })

    constructor(private _notificationsService: NotificationsService,
                private _authService: AuthService) {
    }

    ngOnInit(): void {
        this.userRoles = this._authService.getUserRoles()
    }

    submitNotificationTopic() {
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }

        this._notificationsService.sendNotificationToTopic(this.notificationTopicForm.value)
            .subscribe((result) => {
                    alert('You have successfully sent a topic notification.')
                    this.notificationTopicForm.reset()
                }
            )
    }

}
