import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EpochsComponent} from "./epochs/epochs.component";
import {ActionTypesComponent} from "./action-types/action-types.component";
import {FaqComponent} from "./faq/faq.component";
import {IssueComponent} from "./issue/issue.component";
import {LoginComponent} from "./login/login.component";
import {ConfigComponent} from "./config/config.component";
import {IsAuthenticatedGuard} from "./service/auth/is-authenticated.guard";
import {RegisterComponent} from "./register/register.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {NotificationsComponent} from "./notifications/notifications.component";
import {DismissedActionsComponent} from "./dismissed-actions/dismissed-actions.component";
import {RewardCodeComponent} from "./reward-code/reward-code.component";
import {ClearUserDataComponent} from "./clear-user-data/clear-user-data.component";
import {AdvertisementsComponent} from "./advertisements/advertisements.component";

// david: for auth add the folowing line next to which path u want to be secured
// canActivate: [IsAuthenticatedGuard]

const routes: Routes = [
    {path: '', redirectTo: 'epochs', pathMatch: 'full'},
    {path: 'epochs', component: EpochsComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'actions', component: ActionTypesComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'faq', component: FaqComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'issues', component: IssueComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'login', component: LoginComponent},
    {path: 'notifications', component: NotificationsComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'config', component: ConfigComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'register', component: RegisterComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'statistics', component: StatisticsComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'dismissed-actions', component: DismissedActionsComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'reward-code', component: RewardCodeComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'clear-user-data', component: ClearUserDataComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'advertisements', component: AdvertisementsComponent, canActivate: [IsAuthenticatedGuard]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
