import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {distinctUntilChanged} from "rxjs";
import {RewardCodeService} from "../service/reward-code/reward-code.service";

@Component({
    selector: 'app-reward-code',
    templateUrl: './reward-code.component.html',
    styleUrls: ['./reward-code.component.css']
})
export class RewardCodeComponent implements OnInit {

    rewardCodeForm = new FormGroup({
        amount: new FormControl(null),
        defaultAmount: new FormControl(true)
    })
    rewardQrCode?: string = undefined;

    constructor(private rewardCodeService: RewardCodeService) {
    }

    ngOnInit(): void {
        const amountForm = this.rewardCodeForm.get('amount')
        amountForm?.disable()
        this.rewardCodeForm.get('defaultAmount')?.valueChanges
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe({
                next: value => {
                    if (value) {
                        amountForm?.disable()
                        amountForm?.setValue(null)
                    } else {
                        amountForm?.enable()
                    }
                }
            })
    }

    generateRewardCode() {
        const codeRewardAmount = this.rewardCodeForm.get('amount')?.value
        this.rewardCodeService.generateRewardCode(codeRewardAmount)
            .subscribe({
                next: value => {
                    console.log('Submitted Reward Code, received back ', value)
                    this.rewardQrCode = value.code
                }
            })
    }
}