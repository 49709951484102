import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionType} from "../model/action-type";

import {Subject, zip} from "rxjs";
import {ActionTypeService} from "../service/action-types/action-type.service";
import {DialogBoxComponent} from "../dialog-box/dialog-box.component";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {DialogActions} from "../model/dialog-actions.enum";
import {ColumnDto} from "../model/column.dto";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";

@Component({
  selector: 'app-action-types',
  templateUrl: './action-types.component.html',
  styleUrls: ['./action-types.component.css']
})
export class ActionTypesComponent implements OnInit, OnDestroy {

  userRoles: string[] | null = []
  actionTypePagination: Pagination<ActionType> =  <Pagination<ActionType>>{}
  columns: ColumnDto[] = [];

  displayedColumns: string[] = ['methodId', 'methodDescription', 'rewardPayment', 'action'];
  private readonly _destroyJobSubject = new Subject<void>();

  constructor(private _actionTypeService: ActionTypeService, private dialog: MatDialog, private _authService: AuthService) {
  }

  ngOnInit(): void {
    zip(
      this._actionTypeService.getActionTypesPagination(1),
      this._actionTypeService.getColumns()
    ).pipe(
      takeUntil(this._destroyJobSubject)
    ).subscribe(([actionTypes, columns]) => {
      this.actionTypePagination = actionTypes
      this.columns = columns
    })

    this.userRoles = this._authService.getUserRoles()
  }


  openDialog(action: string, obj: any) {
    if (!this.userRoles?.some(value => value == 'ADMIN')) {
      alert("You do not have admin privileges.")
      return
    }
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '400px',
      height: '400px',
      data: {
        data: obj,
        columns: this.columns,
        action
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == DialogActions.Update) {
        this.updateRowData(this.mapArrayToActionType(result.data.updatedValue))
        this._actionTypeService.updateActionType(this.mapArrayToActionType(result.data.updatedValue)).subscribe()
        }
      }
    );
  }

  private updateRowData(row_obj: ActionType) {
    this.actionTypePagination.items.find(element => {
      if (element.methodId == row_obj.methodId) {
        element.methodDescription = row_obj.methodDescription
        element.rewardPayment = row_obj.rewardPayment
      }
    })
  }

  mapArrayToActionType(elements: Map<string,any>): ActionType {
    return <ActionType>{
      methodId: elements.get("methodId"),
      methodDescription: elements.get("methodDescription"),
      rewardPayment: elements.get("rewardPayment")
    }
  }

  ngOnDestroy(): void {
    this._destroyJobSubject.next()
    this._destroyJobSubject.complete()
  }

  getEpochPaginate($event: number) {
    this._actionTypeService.getActionTypesPagination($event)
        .pipe(
            takeUntil(this._destroyJobSubject)
        ).subscribe(it => this.actionTypePagination = it)
  }
}
