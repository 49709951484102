import {Component, OnInit} from '@angular/core';
import {EpochService} from "../service/epochs/epoch.service";
import {saveAs as importedSaveAs} from "file-saver";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

    epochStatisticsForm = new FormGroup({
        epochId: new FormControl('', [Validators.required]),
    })

    userStatisticsForm = new FormGroup({
        epochId: new FormControl('',[Validators.required]),
    })

    constructor(private _epochService: EpochService) {
    }

    ngOnInit(): void {
    }

    downloadEpochFile() {
        const epochId = this.epochStatisticsForm.get('epochId')?.value
        if (epochId)
            this._epochService.getEpochStatistics(epochId).subscribe(blob => importedSaveAs(blob, `epoch-statistics-${epochId}.csv`))
        else
            alert('Please input the epoch Id')
    }

    downloadUserStatisticsFile() {
        const epochId = this.userStatisticsForm.get('epochId')?.value
        if (epochId)
            this._epochService.getUserPerEpochStatistics(epochId).subscribe(blob => importedSaveAs(blob, `epoch-user-statistics-${epochId}.csv`))
        else
            alert('Please fill out the input fields')
    }

}