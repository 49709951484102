import {Component, OnInit} from '@angular/core';
import {Config} from "../model/config";
import {MatDialog} from "@angular/material/dialog";
import {ConfigService} from "../service/config/config.service";
import {zip} from "rxjs";
import {ColumnDto} from "../model/column.dto";
import {DialogBoxComponent} from "../dialog-box/dialog-box.component";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

  userRoles: string[] | null = []
  configPagination: Pagination<Config> = <Pagination<Config>>{}
  columns: ColumnDto[] = []

  displayedColumns: string[] = ['id', 'value', 'action'];

  constructor(private _configService: ConfigService, private dialog: MatDialog, private _authService: AuthService) {
  }

  ngOnInit(): void {
    zip(
      this._configService.getConfigPagination(1),
      this._configService.getColumns()
    ).subscribe(([configs, columns]) => {
      this.configPagination = configs
      this.columns = columns
    })
    this.userRoles = this._authService.getUserRoles()
  }

  openDialog(action: string, obj: any) {
    if (!this.userRoles?.some(value => value == 'ADMIN')) {
      alert("You do not have admin privileges.")
      return
    }
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '400px',
      height: '350px',
      data: {
        data: obj,
        columns: this.columns,
        action
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'Update') {
          this.updateRowData(this.mapArrayToConfig(result.data.updatedValue))
          this._configService.updateConfig(this.mapArrayToConfig(result.data.updatedValue)).subscribe()
        }
      }
    );
  }

  private updateRowData(row_obj: Config) {
    this.configPagination.items = this.configPagination.items.filter((value) => {
      if (value.id == row_obj.id) {
        value.value = row_obj.value
      }
      return true
    })
  }

  mapArrayToConfig(elements: Map<string, any>): Config {
    return <Config>{
      id: elements.get("id"),
      value: elements.get("value"),
      type: elements.get("type"),
    }
  }

  getIssuePaginate($event: number) {
    this._configService.getConfigPagination($event)
        .subscribe(it => this.configPagination = it)
  }
}
