import {Component, OnDestroy, OnInit} from '@angular/core';
import {EpochService} from "../service/epochs/epoch.service";
import {Subject, zip,} from "rxjs";
import {Epoch} from "../model/epoch";
import {takeUntil} from "rxjs/operators";
import {ColumnDto} from "../model/column.dto";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";

@Component({
    selector: 'app-epochs',
    templateUrl: './epochs.component.html',
    styleUrls: ['./epochs.component.css']
})
export class EpochsComponent implements OnInit, OnDestroy {

    userRoles: string[] | null = []
    epochPagination: Pagination<Epoch> = <Pagination<Epoch>>{}
    columns: ColumnDto[] | undefined

    private readonly _destroyJobSubject = new Subject<void>();

    constructor(private _epochService: EpochService, private _authService: AuthService) {
    }

    ngOnInit(): void {
        this.init()
    }

    updateEpoch(value: Epoch) {
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }
        this._epochService.updateEpoch(value).subscribe()
    }

    deletedEpoch(deletedStatus: boolean) {
        if(deletedStatus)
            this.init()
    }

    init() {
        zip(
            this._epochService.getEpochsPagination(1),
            this._epochService.getColumns()
        ).pipe(
            takeUntil(this._destroyJobSubject)
        ).subscribe(
            ([epochs, columns]) => {
                this.epochPagination = epochs
                this.columns = columns
            }
        )
        this._epochService.getEpochsPagination(1)
            .pipe(
                takeUntil(this._destroyJobSubject)
            ).subscribe(result => this.epochPagination = result)
        this.userRoles = this._authService.getUserRoles()
    }

    ngOnDestroy(): void {
        this._destroyJobSubject.next()
        this._destroyJobSubject.complete()
    }

    getEpochPaginate($event: number) {
        this._epochService.getEpochsPagination($event)
            .pipe(
                takeUntil(this._destroyJobSubject)
            )
            .subscribe(it => this.epochPagination = it)
    }
}
