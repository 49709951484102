import {Component, OnInit} from '@angular/core';
import {Issue} from "../model/issue";
import {IssueService} from "../service/issue/issue.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";
import {FilterSortOptionsDto, GridFilters} from "../model/grid-column/filter-sort-options.dto";
import {SortOrderEnum} from "../model/enum/sort-order.enum";
import {zip} from "rxjs";
import {GridColumn, GridColumnTypeEnum} from "../model/grid-column/grid-column.dto";


@Component({
    selector: 'app-issue',
    templateUrl: './issue.component.html',
    styleUrls: ['./issue.component.css']
})
export class IssueComponent implements OnInit {

    userRoles: string[] | null = []
    issuePagination: Pagination<Issue> = <Pagination<Issue>>{}
    filterSortOptionsDto = new FilterSortOptionsDto()
    gridColumn: GridColumn[] = []
    gridColumnNames: string[] = []
    filters: { [key: string]: string } = {};
    selectedValue: { [key: string]: GridFilters } = {};

    displayedColumns: string[] = ['title', 'description', 'contact_info', 'device_id', 'image', 'is_solved', 'timeCreated', 'timeUpdated', 'userId', 'correlationId', 'phoneManufacturer', 'phoneModel'];

    constructor(private _issuesService: IssueService, private _authService: AuthService) {
    }

    ngOnInit(): void {
        zip(this._issuesService.getIssuesPagination(1, this.filterSortOptionsDto), this._issuesService.getGridColumns())
            .subscribe(([issues, gridColumns]) => {
                this.issuePagination = issues
                this.gridColumn = gridColumns
                this.gridColumnNames = this.gridColumn.map(it => it._columnName)
            })

        this.userRoles = this._authService.getUserRoles()

    }

    solveIssue(issueId: string, $event: MatCheckboxChange) {
        if (!this.userRoles?.some(value => value == 'ADMIN')) {
            alert("You do not have admin privileges.")
            return
        }
        this._issuesService.setIssueStatus(issueId, $event.checked).subscribe()
    }

    getIssuePaginate($event: number) {
        this._issuesService.getIssuesPagination($event, this.filterSortOptionsDto).subscribe(issues => {
            this.issuePagination = issues
        })
    }

    sortByColumn(sortColumn: string) {
        this.filterSortOptionsDto.sortColumn = sortColumn

        if (this.filterSortOptionsDto.sortOrder === SortOrderEnum.DESC)
            this.filterSortOptionsDto.sortOrder = SortOrderEnum.ASC
        else
            this.filterSortOptionsDto.sortOrder = SortOrderEnum.DESC

        this.getIssuePaginate(this.issuePagination.meta.currentPage)
    }

    protected readonly GridFilters = GridFilters;

    applyFilter() {
        this.filterSortOptionsDto.filterOptions = []
        this.gridColumnNames.forEach(columnName => {
            if (this.filters[columnName]) {
                this.filterSortOptionsDto.filterOptions.push({
                    filterValue: this.filters[columnName],
                    filterGrid: GridFilters[this.selectedValue[columnName]],
                    filterColumn: columnName
                })
            }
        })

        this._issuesService.getIssuesPagination(this.issuePagination.meta.currentPage, this.filterSortOptionsDto).subscribe(issues => {
            this.issuePagination = issues
        })
    }

    clearFilters() {
        this.filters = {}
        this.filterSortOptionsDto.filterOptions = []
        this.ngOnInit()
    }

    public readonly GridColumnTypeEnum = GridColumnTypeEnum;
}
