import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JsonEditorOptions} from "ang-jsoneditor";

@Component({
    selector: 'app-dialog-view-json',
    templateUrl: './dialog-view-json.component.html',
    styleUrls: ['./dialog-view-json.component.css']
})
export class DialogViewJsonComponent implements OnInit {

    @Input() data: any
    @Output() closedDialog = new EventEmitter<void>();

    jsonEditorOptions!: JsonEditorOptions;

    constructor() {
        this.jsonEditorOptions = new JsonEditorOptions()
        this.jsonEditorOptions.mode = 'view'// set form allowed so that keys and values are not editable.
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.closedDialog.emit()
    }
}
