import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import * as Buffer from "buffer";

@Component({
    selector: 'app-dialog-view-image',
    templateUrl: './dialog-view-image.component.html',
    styleUrls: ['./dialog-view-image.component.css']
})
export class DialogViewImageComponent implements OnInit {

    @Input() data: any
    @Output() closedDialog = new EventEmitter<void>();

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
    }

    transform(){
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.data);
    }


    closeDialog() {
        this.closedDialog.emit()
    }

}
