import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AdvertisementDto} from "../../model/advertisement.dto";
import {environment} from "../../../environments/environment";
import {ColumnDto} from "../../model/column.dto";

@Injectable({
    providedIn: 'root'
})
export class AdvertisementsService {

    baseUrl = environment.apiUrl + "/advertisements"

    constructor(private httpClient: HttpClient) {
    }

    getAllAdvertisements(): Observable<AdvertisementDto[]> {
        return this.httpClient.get<AdvertisementDto[]>(this.baseUrl)
    }

    getActiveAdvertisements(): Observable<AdvertisementDto[]> {
        return this.httpClient.get<AdvertisementDto[]>(`${this.baseUrl}/active`)
    }

    updateAdvertisement(advertisement: AdvertisementDto): Observable<AdvertisementDto> {
        return this.httpClient.patch<AdvertisementDto>(this.baseUrl, advertisement)
    }

    deleteAdvertisement(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/${id}`)
    }

    getPossiblePlacements(): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.baseUrl}/placements`)
    }

    getColumns(): Observable<ColumnDto[]> {
        return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/columns`)
    }

    createAdvertisement(advertisement: AdvertisementDto): Observable<AdvertisementDto> {
        return this.httpClient.post<AdvertisementDto>(this.baseUrl, advertisement)
    }
}
