import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RewardCodeResponse} from "./reward-code.interface";

@Injectable({
    providedIn: 'root'
})
export class RewardCodeService {
    baseUrl = environment.apiUrl + "/rewards/code/generate"

    constructor(private httpClient: HttpClient) {
    }

    generateRewardCode(amount?: number): Observable<RewardCodeResponse> {
        return this.httpClient.post<RewardCodeResponse>(
            `${this.baseUrl}/web`, {
                amount
            }
        )
    }

}