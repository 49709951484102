import {SortOrderEnum} from "../enum/sort-order.enum";

export class FilterSortOptionsDto {
    sortColumn?: string = undefined
    sortOrder?: SortOrderEnum = SortOrderEnum.DESC
    filterOptions: FilterOptionsDto[] = []
}

export class FilterOptionsDto {
    filterColumn?: string = undefined
    filterValue?: string = undefined
    filterGrid?: GridFilters = undefined
}

export enum GridFilters {
    EQUALS = 'EQUALS',
    LIKE = 'LIKE',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
}