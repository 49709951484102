import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Faq} from "../../model/faq";
import {ColumnDto} from "../../model/column.dto";
import {environment} from "../../../environments/environment";
import {Pagination} from "../../model/pagination";
import {PaginationService} from "../pagination.service";
import {MyHttpParam} from "../../model/http-param";
import {FaqDto} from "../../model/faq.dto";

@Injectable({
    providedIn: 'root'
})
export class FaqService {

    baseUrl = environment.apiUrl + "/faq"

    constructor(
        private paginationService: PaginationService<Faq>,
        private httpClient: HttpClient) {
    }

    getColumns(): Observable<ColumnDto[]> {
        return this.httpClient.get<ColumnDto[]>(`${this.baseUrl}/columns`)
    }

    getFaqPagination(page: number): Observable<Pagination<Faq>> {
        const params: MyHttpParam[] = [{name: 'page', value: page}]
        return this.paginationService.getPagination(`${this.baseUrl}/paginate`, params)
    }

    createFaq(faq: FaqDto): Observable<Faq> {
        return this.httpClient.post<Faq>(this.baseUrl, faq)
    }

    updateFaq(faq: Faq): Observable<Faq> {
        return this.httpClient.patch<Faq>(this.baseUrl, faq)
    }

    deleteFaq = (faqId: number): Observable<Object> =>
        this.httpClient.delete(`${this.baseUrl}/${faqId}`)
}
