import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NotificationTopicRequestDto} from 'src/app/model/notification-topic-request.dto';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    baseUrl = environment.apiUrl + "/api/push-notification"

    constructor(private httpClient: HttpClient) {
    }

    sendNotificationToTopic(notificationTopicRequestDto: NotificationTopicRequestDto) {
        return this.httpClient.post<string>(
            `${this.baseUrl}/notify-all`, notificationTopicRequestDto, {
                responseType: 'text' as 'json'
            }
        )
    }

}