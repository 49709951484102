import {GridFilters} from "./filter-sort-options.dto";


export enum GridColumnTypeEnum {
    TEXT,
    UUID,
    JSON,
    NUMBER,
    DATE,
    LARGE_TEXT,
    BOOLEAN,
    FILE,
    SELECT,
    IMAGE
}

interface GridColumnType {
    type: GridColumnTypeEnum;
    filters: GridFilters[];
}

export interface GridColumn {
    _columnName: string
    _prettyColumnName: string
    _type: GridColumnType
    _isFilterable: boolean
    _isEditable: boolean
    _isSortable: boolean
}