<div>
    <h1>Dismissed Actions</h1>
</div>

<nb-card>
    <nb-card-body>
        <input nbInput #userId
               (keyup.enter)="updateAndSearchByUserId(userId.value)" placeholder="User ID">
        <button nbButton shape="semi-round" size="small" (click)="updateAndSearchByUserId(userId.value)">Search
        </button>
    </nb-card-body>
</nb-card>

<div *ngIf="dismissedActionsPagination">

    <table mat-table [dataSource]="dismissedActionsPagination.items" class="mat-elevation-z8 demo-table" #table>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element"> {{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef>userId</th>
            <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
        </ng-container>

        <ng-container matColumnDef="deviceId">
            <th mat-header-cell *matHeaderCellDef>Device Id</th>
            <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
        </ng-container>

        <ng-container matColumnDef="actionType">
            <th mat-header-cell *matHeaderCellDef>Action Type</th>
            <td mat-cell *matCellDef="let element"> {{element.actionType}} </td>
        </ng-container>

        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button nbButton size="small" status="success"
                        (click)="openDialog('View',element.data)">View data
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="timeCreated">
            <th mat-header-cell *matHeaderCellDef>Time Created</th>
            <td mat-cell *matCellDef="let element"> {{element.timeCreated}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <app-pager
            [totalItems]="dismissedActionsPagination?.meta?.totalItems"
            (newItemEvent)="getDismissedActionsPagination($event)">
    </app-pager>
</div>

